$assetPath: "/public/assets/img/hero/hero-bg.png";

// hover
.offer-list-item {
  color: $black;
  transition: 0.3s ease;
  transition-property: color, background-color;
  padding: 1.8rem 1rem;
  margin: 1.5rem 0;
  border-radius: 0.312rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;

  &:hover {
    background-color: $dark;
    color: white;
  }
}

// accordion
.accordion-button {

  &::after {
    background-color: $dark;
    border-radius: 100%;
    width: 1.75rem !important;
    height: 1.75rem !important;
    background-position: 58.5% 71%;
  }
}

.accordion-button:not(.collapsed) {
  &::after {
    background-position: 50% 50% !important;
  }
}